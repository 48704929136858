import React, { Component } from 'react';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
// import enUS from 'antd/lib/locale-provider/en_US';
import zh_TW from 'antd/lib/locale-provider/zh_TW';
import store from "./store";
import Router from "./router";
class App extends Component {
  
  render() { 
    return (
      <ConfigProvider locale={zh_TW}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ConfigProvider>
    );
  }
}
 
export default App;
