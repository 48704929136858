import React, { useState } from 'react';

import { Form, Input, Row, Col, Button} from "antd";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

const Contact = (props) => {

    const [disabledContact, setDisabledContact] = useState(false)

    return (
        <>
            <Form.List name="contacts"
                    rules={[
                    {
                        validator: async (_, names) => {
                        setDisabledContact(names.length >= 2)
                        },
                    },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        
                    <>
                        {fields.map(({ key, name, ...restField }, { errors }) => (
                        
                        <Row
                            key={key}
                            className="box-info"
                        >
                            <Col span={24}>
                            <Form.Item
                                {...restField}
                                label="Name"
                                name={[name, 'contact_name']}
                                rules={[
                                {
                                    required: true,
                                    message: "The contact name is required",
                                },
                                ]}
                            >
                                <Input placeholder="Contact name" />
                            </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    {...restField}
                                    label="Phone"
                                    name={[name, 'contact_phone']}
                                    rules={[
                                    {
                                        required: true,
                                        message: "The contact phone is required",
                                    },
                                    ]}
                                >
                                    <Input placeholder="Contact phone" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                            <Form.Item
                                {...restField}
                                label="Address"
                                name={[name, 'contact_address']}
                                rules={[
                                {
                                    required: true,
                                    message: "The contact address is required",
                                },
                                ]}
                            >
                                <Input placeholder="Contact address" />
                            </Form.Item>
                            </Col>
                            <Col span={24}>
                            <Form.Item
                            {...restField}
                            label="Time"
                            name={[name, 'contact_time']}
                            rules={[
                                {
                                required: true,
                                message: "The contact time is required",
                                },
                            ]}
                            >
                            <Input placeholder="Contact time" />
                            </Form.Item>
                            </Col>
                            {key !== 0 ? <Col span={24} className="remove-info">
                                <MinusCircleOutlined style={{fontSize: '18px', color: 'red'}} onClick={() => remove(name)} />
                            </Col> : null}

                        </Row>
                        ))}
                        
                        <Form.Item>
                            <Button type="dashed" disabled={disabledContact} onClick={() => add()} icon={<PlusOutlined />}>
                                Add contact
                            </Button>
                        </Form.Item>
                    </>
                    )}
            </Form.List>
        </>
    )
}

export default Contact