import React from 'react';
import { Table, Checkbox } from 'antd';

const WeekSchedule = ({schedules, handleUpdateSchedule}) => {

    const columns = [
        {
            title: '',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '星期一',
            dataIndex: 'mon',
            key: 'mon',
        },
        {
            title: '星期二',
            dataIndex: 'tue',
            key: 'tue',
        },
        {
            title: '星期三',
            dataIndex: 'wed',
            key: 'wed',
        },
        {
            title: '週四',
            dataIndex: 'thu',
            key: 'thu',
        },
        {
            title: '星期五',
            dataIndex: 'fri',
            key: 'fri',
        },
        {
            title: '週六',
            dataIndex: 'sat',
            key: 'sat',
        },
        {
            title: '週日',
            dataIndex: 'sun',
            key: 'sun',
        },
    ]

    const onChange = (e) => {
        handleUpdateSchedule(e.target.name)
    }

    const data = [
        {
            key: '1',
            type: '午前',
            mon: <Checkbox name="is_mon_morning" checked={schedules['is_mon_morning']} onChange={onChange}></Checkbox>,
            tue: <Checkbox name="is_tue_morning" checked={schedules['is_tue_morning']} onChange={onChange}></Checkbox>,
            wed: <Checkbox name="is_wed_morning" checked={schedules['is_wed_morning']} onChange={onChange}></Checkbox>,
            thu: <Checkbox name="is_thu_morning" checked={schedules['is_thu_morning']} onChange={onChange}></Checkbox>,
            fri: <Checkbox name="is_fri_morning" checked={schedules['is_fri_morning']} onChange={onChange}></Checkbox>,
            sat: <Checkbox name="is_sat_morning" checked={schedules['is_sat_morning']} onChange={onChange}></Checkbox>,
            sun: <Checkbox name="is_sun_morning" checked={schedules['is_sun_morning']} onChange={onChange}></Checkbox>,
        },
        {
            key: '2',
            type: '午後',
            mon: <Checkbox name="is_mon_afternoon" checked={schedules['is_mon_afternoon']} onChange={onChange}></Checkbox>,
            tue: <Checkbox name="is_tue_afternoon" checked={schedules['is_tue_afternoon']} onChange={onChange}></Checkbox>,
            wed: <Checkbox name="is_wed_afternoon" checked={schedules['is_wed_afternoon']} onChange={onChange}></Checkbox>,
            thu: <Checkbox name="is_thu_afternoon" checked={schedules['is_thu_afternoon']} onChange={onChange}></Checkbox>,
            fri: <Checkbox name="is_fri_afternoon" checked={schedules['is_fri_afternoon']} onChange={onChange}></Checkbox>,
            sat: <Checkbox name="is_sat_afternoon" checked={schedules['is_sat_afternoon']} onChange={onChange}></Checkbox>,
            sun: <Checkbox name="is_sun_afternoon" checked={schedules['is_sun_afternoon']} onChange={onChange}></Checkbox>,
        },
        {
            key: '3',
            type: '夕方',
            mon: <Checkbox name="is_mon_evening" checked={schedules['is_mon_evening']} onChange={onChange}></Checkbox>,
            tue: <Checkbox name="is_tue_evening" checked={schedules['is_tue_evening']} onChange={onChange}></Checkbox>,
            wed: <Checkbox name="is_wed_evening" checked={schedules['is_wed_evening']} onChange={onChange}></Checkbox>,
            thu: <Checkbox name="is_thu_evening" checked={schedules['is_thu_evening']} onChange={onChange}></Checkbox>,
            fri: <Checkbox name="is_fri_evening" checked={schedules['is_fri_evening']} onChange={onChange}></Checkbox>,
            sat: <Checkbox name="is_sat_evening" checked={schedules['is_sat_evening']} onChange={onChange}></Checkbox>,
            sun: <Checkbox name="is_sun_evening" checked={schedules['is_sun_evening']} onChange={onChange}></Checkbox>,
        },
    ]

    return (
        <>
            <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={data}  pagination={false} />
        </>
    )
}

export default WeekSchedule;