const menuList = [
  // {
  //   title: "儀表板",
  //   path: "/dashboard",
  //   icon: "DashboardOutlined",
  //   roles:["admin"]
  // },
  {
    title: '報名',
    path: '/applies',
    icon: 'CheckCircleOutlined',
    roles: ['admin'],
  },
  {
    title: '諮詢',
    path: '/contacts',
    icon: 'ContactsOutlined',
    roles: ['admin'],
  },
  {
    title: '課程',
    path: '/courses',
    icon: 'DatabaseOutlined',
    roles: ['admin'],
  },
  {
    title: '活動',
    path: '/campaigns',
    icon: 'GiftOutlined',
    roles: ['admin'],
  },
  {
    title: '開課日期',
    path: '/schedules',
    icon: 'CalendarOutlined',
    roles: ['admin'],
  },
  {
    title: '學生心得',
    path: '/interviews',
    icon: 'UsergroupAddOutlined',
    roles: ['admin'],
  },
  {
    title: '日文學習',
    path: '/blogs',
    icon: 'ReadOutlined',
    roles: ['admin', 'blogs'],
  },
  {
    title: '訊息',
    path: '/news',
    icon: 'BookOutlined',
    roles: ['admin', 'news'],
  },

  {
    title: 'Questions',
    path: '/knowledge/questions',
    icon: 'QuestionCircleOutlined',
    roles: ['admin', 'knowledge'],
  },
  {
    title: 'Answers',
    path: '/knowledge/answers',
    icon: 'CheckCircleOutlined',
    roles: ['admin', 'knowledge'],
  },
  {
    title: 'Users',
    path: '/knowledge/users',
    icon: 'UserOutlined',
    roles: ['admin', 'knowledge'],
  },
  {
    title: 'Teachers',
    path: '/knowledge/teachers',
    icon: 'UserOutlined',
    roles: ['admin', 'knowledge'],
  },
  {
    title: 'Search keyword',
    path: '/knowledge/search',
    icon: 'FileSearchOutlined',
    roles: ['admin', 'knowledge'],
  },
  {
    title: 'Dmm data',
    path: '/knowledge/dmm',
    icon: 'DatabaseOutlined',
    roles: ['admin', 'knowledge'],
  },

  {
    title: '常見問題',
    path: '/faqs',
    icon: 'QuestionCircleOutlined',
    roles: ['admin'],
  },
  {
    title: '人才招募',
    path: '/recruitments',
    icon: 'FireOutlined',
    roles: ['admin'],
  },
  {
    title: '教室',
    path: '/rooms',
    icon: 'BankOutlined',
    roles: ['admin'],
  },
  {
    title: '發送郵件',
    path: '/mails',
    icon: 'MailOutlined',
    roles: ['admin'],
  },
  {
    title: '郵件紀錄',
    path: '/mail-logs',
    icon: 'MailOutlined',
    roles: ['admin'],
  },
  {
    title: '三分鐘日語',
    path: '/3mins',
    icon: 'VideoCameraOutlined',
    roles: ['admin'],
  },
  {
    title: '課程分級',
    path: '/overviews',
    icon: 'MoneyCollectOutlined',
    roles: ['admin'],
  },
  {
    title: '分析',
    path: '/analytics',
    icon: 'PieChartOutlined',
    roles: ['admin'],
  },
  {
    title: 'Database',
    path: '/options',
    icon: 'SettingOutlined',
    roles: ['admin'],
  },
  {
    title: 'Statistical',
    path: '/search-statistical',
    icon: 'PieChartOutlined',
    roles: ['admin'],
  },
];
export default menuList;
