import axios from "axios";
import store from "@/store";
import { Modal, message } from "antd";
import { getToken } from "@/utils/auth";
import { logout } from "@/store/actions";

//Create an axios example
const service = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL_API, // api base_url
  headers: {
    'Content-Type': 'application/json'
  },
  crossDomain: true,
  withCredentials: true,
  timeout: 12000, // request timeout
});

// Request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getState().user.token) {
      config.headers.Authorization = 'Bearer ' + getToken();
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

// Response interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    message.error(error);
    // const { status } = error.response;
    // if (status === 403) {
    //   Modal.confirm({
    //     title: "Logout?",
    //     content:
    //       "You have been logged out due to inactivity for a long time, you can cancel to stay on this page, or log in again",
    //     okText: "OK",
    //     cancelText: "Cancel",
    //     onOk() {
    //       let token = store.getState().user.token;
    //       store.dispatch(logout(token));
    //     },
    //     onCancel() {
    //       console.log("Cancel");
    //     },
    //   });
    // }
    return Promise.reject(error);
  }
);

export default service;
