import React, { useEffect, useState } from 'react'; 
import { Upload, message, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { reqUploadImage } from "@/api/upload";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
    message.error('Image must smaller than 5MB!');
    }
    return isJpgOrPng && isLt5M;
};
  

const UploadImage = (props) => {

    const {index, name, onUploaded, oldValues, onRemoved, fileList, maxCount} = props

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [images, setImages] = useState(fileList);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');  

    const [newImages, setNewImages] = useState([])

    const uploadButton = (
        <div>
          {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{marginTop: 8}} > 上傳 </div>
        </div>
    );
    
    const handleChange = ({file, fileList}) => { 
      if (file.status === undefined) return;
      setImages(fileList) 
    }

    const onRemove = (file) => { 
        if (maxCount > 1) {

          const uid = file.uid
          const updateNewImages = newImages.filter(image => image.uid !== uid)
          setNewImages(updateNewImages)
          onUploaded({index, name, data: updateNewImages})

        } else {
          setImages([])
          onUploaded({index, name, data: null})
        }
       
    }

    const customRequest = async ({onProgress, onError, onSuccess, file }) => {
       
        let formData = new FormData();
        formData.append('image', file)
        formData.append('uid', file.uid)
        formData.append('name', file.name)
  
        let response = await reqUploadImage(formData)
        let {success, data, errors} = response.data

        if (success) {
            onSuccess('OK')
           
            Object.assign(data, {'file_name': name})

            //upload multiple
            if (maxCount > 1) {

              newImages.push(data)
              setNewImages(newImages)
              onUploaded({index, name, data: newImages})
              
            } else {
              onUploaded({index, name, data})
            }
           
            
          } else {
            onError(errors)
          }
    }
     
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    useEffect(() => {
        let isMounted = false;
        if (!isMounted) {
          setImages(fileList)
          setNewImages(oldValues || [])
        }
  
        return () => {
          isMounted = true
        }
    }, [fileList])
    
    return (
        <>
           <Upload
                data={{
                index: index,
                name : name
                }}
                listType="picture-card"
                className="avatar-uploader"
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={customRequest}
                fileList={images}
                onRemove={onRemove}
                multiple
                maxCount={maxCount} >
                {images.length >= maxCount ? null : uploadButton}
            </Upload>

           <Modal open={previewOpen} title={'Preview'} footer={null} onCancel={handleCancel}>
              <img
                alt="Cover"
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
        </>
    )
}

export default UploadImage;