import request from '@/utils/request'

export function reqOptions() {
    return request({
      url: '/options',
      method: 'get',
    })
}
export function reqGetTableOptions(data) {
    return request({
      url: `/table-options?page=${data.page}&per_page=${data.per_page}`,
      method: 'get',
    })
}

export function reqGetOptionDetail(data) {
    return request({
      url: `/options/${data.id}?page=${data.page}&per_page=${data.per_page}`,
      method: 'get',
    })
}
export function reqGetOptionChildren(data) {
  return request({
    url: `/options/${data.id}/children?page=${data.page}&per_page=${data.per_page}`,
    method: 'get',
  })
}

export function reqDeleteOption(id) {
    return request({
      url: `/options/${id}`,
      method: 'delete',
    })
}
export function reqUpdateOption(data) {
    return request({
      url: `/options/${data.id}`,
      method: 'put',
      data
    })
}
export function reqAddOption(data) {
    return request({
      url: `/options`,
      method: 'post',
      data
    })
}