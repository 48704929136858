import request from '@/utils/request'

export function reqGetCourses(data) {
    return request({
      url: `/courses?page=${data.page}&per_page=${data.per_page}`,
      method: 'get',
    })
}

export function reqGetCourse(id) {
  return request({
    url: `/courses/${id}`,
    method: 'get',
  })
}
export function reqUpdateCourse(data) {
  return request({
    url: `/courses/${data.id}`,
    method: 'put',
    data: {
      ...data
    }
  })
}

export function reqDeleteCourse(id) {
  return request({
    url: `/courses/${id}`,
    method: 'delete',
  })
}

export function reqCompanies() {
  return request({
    url: `/courses/companies`,
    method: 'get',
  })
}
export function reqGetJapanSchool(data) {
  return request({
    url: `/courses/${data.id}/japan-school?page=${data.page}&per_page=${data.per_page}`,
    method: 'get',
  })
}
export function reqDeleteJapanSchool(id) {
  return request({
    url: `/courses/japan-school/${id}`,
    method: 'delete',
  })
}

export function reqAddJapanSchool(data) {
  return request({
    url: `/courses/${data.course_id}/add-japan-school`,
    method: 'post',
    data
  })
}

export function reqUpdateJapanSchool(data) {
  return request({
    url: `/courses/japan-school/${data.id}`,
    method: 'put',
    data
  })
}

export function reqUpdateOrder(data) {
  return request({
    url: `/courses/update-order`,
    method: 'put',
    data
  })
}

export function reqUpdateNote(data) {
  return request({
    url: `/courses/update-note`,
    method: 'put',
    data,
  });
}

export function reqDeleteMailBcc(data) {
  return request({
    url: `/courses/delete-mail-bcc`,
    method: 'put',
    data,
  })
}

export function reqAddMailBcc(data) {
  return request({
    url: `/courses/add-mail-bcc`,
    method: 'put',
    data,
  })
}
export function reqUpdateMailBcc(data) {
  return request({
    url: `/courses/update-mail-bcc`,
    method: 'put',
    data,
  })
}
export function reqGetCampaigns(data) {
  return request({
    url: `/courses/${data.id}/campaigns?page=${data.page}&per_page=${data.per_page}`,
    method: 'get',
  })
}
export function reqDeleteCampaign(id) {
  return request({
    url: `/courses/delete-campaign/${id}`,
    method: 'delete'
  })
}
export function reqAddCampaign(data) {
  return request({
    url: `/courses/${data.course_id}/add-campaign`,
    method: 'post',
    data
  })
}
export function reqUpdateCampaign(data) {
  return request({
    url: `/courses/campaign/${data.id}`,
    method: 'put',
    data
  })
}
export function reqUpdateOrderJapan(data) {
  return request({
    url: `/courses/update-order-japan-school`,
    method: 'put',
    data
  })
}
export function reqUpdateLabel(data) {
  return request({
    url: `/courses/update-label`,
    method: 'put',
    data
  })
}
export function reqToggleStatus(id) {
  return request({
    url: `/courses/${id}/toggle-status`,
    method: 'put',
  });
}

export function reqGetSchedule(data) {
  return request({
    url: `/courses/${data.id}/schedules?page=${data.page}&per_page=${data.per_page}`,
    method: 'get',
  });
}