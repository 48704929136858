import React, {useEffect, useState} from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "@/views/layout";
import Login from "@/views/login";
import { useSelector, useDispatch } from 'react-redux';
import {getUserInfo} from '@/store/actions'
import {Spin} from 'antd';
import {getToken} from '@/utils/auth'

const Router = () => {
  const {token} = useSelector(state => state.user);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          path="/"
          render={() => {
            if (!token) {
              return <Redirect to="/login" />;
            } else {
              return  <Layout />;
                
              // return  <Spin spinning={loading} tip="加載中..."></Spin>;
            }
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}
export default Router;
