import Loadable from 'react-loadable';
import Loading from '@/components/Loading'
// const Dashboard = Loadable({loader: () => import('@/views/dashboard'),loading: Loading});
const Course = Loadable({loader: () => import('@/views/course'),loading: Loading});
const CourseEdit = Loadable({loader: () => import('@/views/course-edit'),loading: Loading});
const Schedule = Loadable({loader: () => import('@/views/schedule'),loading: Loading});
const Error404 = Loadable({loader: () => import('@/views/error/404'),loading: Loading});
const Faq = Loadable({loader: () => import('@/views/faq'),loading: Loading});
const Option = Loadable({loader: () => import('@/views/option'),loading: Loading});
const Audio = Loadable({loader: () => import('@/views/audio'),loading: Loading});
const Interview = Loadable({loader: () => import('@/views/interview'),loading: Loading});
const Room = Loadable({loader: () => import('@/views/room'),loading: Loading});
const Blog = Loadable({loader: () => import('@/views/blog'),loading: Loading});
const News = Loadable({loader: () => import('@/views/news'),loading: Loading});
const Contact = Loadable({loader: () => import('@/views/contact'),loading: Loading});
const Apply = Loadable({loader: () => import('@/views/apply'),loading: Loading});
const Recruitment = Loadable({loader: () => import('@/views/recruitment'),loading: Loading});
const Mail = Loadable({loader: () => import('@/views/mail'),loading: Loading});
const MailLog = Loadable({loader: () => import('@/views/mail-log'),loading: Loading});
const Overview = Loadable({loader: () => import('@/views/overview'),loading: Loading});
const Campaign = Loadable({loader: () => import('@/views/campaign'),loading: Loading});
const Analytic = Loadable({loader: () => import('@/views/analytic'),loading: Loading});
const Review = Loadable({loader: () => import('@/views/review'),loading: Loading});
const SearchStatic = Loadable({
  loader: () => import('@/views/search-static'),
  loading: Loading,
});

const Question = Loadable({loader: () => import('@/views/knowledge/questions'),loading: Loading});
const Answer = Loadable({loader: () => import('@/views/knowledge/answers'),loading: Loading});
const User = Loadable({loader: () => import('@/views/knowledge/users'),loading: Loading});
const Teacher = Loadable({loader: () => import('@/views/knowledge/teachers'),loading: Loading});
const Search = Loadable({loader: () => import('@/views/knowledge/search'),loading: Loading});
const Dmm = Loadable({ loader: () => import('@/views/knowledge/dmm'), loading: Loading });


export default [
  // { path: "/dashboard", component: Dashboard, roles: ["admin","editor","guest"] },
  { path: '/courses', component: Course, roles: ['admin'] },
  { path: '/courses/:id', component: CourseEdit, roles: ['admin'] },
  { path: '/schedules', component: Schedule, roles: ['admin'] },
  { path: '/faqs', component: Faq, roles: ['admin'] },
  { path: '/options', component: Option, roles: ['admin'] },
  { path: '/3mins', component: Audio, roles: ['admin'] },
  { path: '/rooms', component: Room, roles: ['admin'] },
  { path: '/interviews', component: Interview, roles: ['admin'] },
  { path: '/blogs', component: Blog, roles: ['admin', 'blogs'] },
  { path: '/news', component: News, roles: ['admin', 'news'] },
  { path: '/contacts', component: Contact, roles: ['admin'] },
  { path: '/applies', component: Apply, roles: ['admin'] },
  { path: '/recruitments', component: Recruitment, roles: ['admin'] },
  { path: '/mails', component: Mail, roles: ['admin'] },
  { path: '/mail-logs', component: MailLog, roles: ['admin'] },
  { path: '/overviews', component: Overview, roles: ['admin'] },
  { path: '/campaigns', component: Campaign, roles: ['admin'] },
  { path: '/analytics', component: Analytic, roles: ['admin'] },
  { path: '/reviews', component: Review, roles: ['admin'] },
  { path: '/search-statistical', component: SearchStatic, roles: ['admin'] },
  {
    path: '/knowledge/questions',
    component: Question,
    roles: ['admin', 'knowledge'],
  },
  {
    path: '/knowledge/answers',
    component: Answer,
    roles: ['admin', 'knowledge'],
  },
  { path: '/knowledge/users', component: User, roles: ['admin', 'knowledge'] },
  {
    path: '/knowledge/teachers',
    component: Teacher,
    roles: ['admin', 'knowledge'],
  },
  {
    path: '/knowledge/search',
    component: Search,
    roles: ['admin', 'knowledge'],
  },
  { path: '/knowledge/dmm', component: Dmm, roles: ['admin', 'knowledge'] },

  { path: '/error/404', component: Error404 },
];
