import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import MenuIcon from './menu-icon';
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { addTag } from "@/store/actions";
import { getMenuItemInMenuListByProperty } from "@/utils";
import "./index.less";

const SubMenu = Menu.SubMenu;

// Update order
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const AdminMenu = () => {

  const [menuTreeNode, setMenuTreeNode] = useState(null);
  const [openKey, setOpenKey] = useState([]);

  const {menus} = useSelector(state => state.user.profile)

  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  

  const getMenuNodes = (menuList) => {
    const path = location.pathname;
    return menuList.reduce((pre, item) => {
        if (!item.children) {
          pre.push(
            {label: (item.path === `/knowledge` ? <a target={`_blank`} href={`${process.env.REACT_APP_FRONTEND_URL}/knowledge`}>
                {item.icon ? <MenuIcon type={item.icon} /> : null}
                <span>{item.title}</span>
            </a>  : <Link to={item.path}>
            {item.icon ? <MenuIcon type={item.icon} /> : null}
            <span>{item.title}</span>
          </Link> ),
          key: item.path
           }
          );
        } else {
          // find a child item math with current path
          const cItem = item.children.find(
            (cItem) => path.indexOf(cItem.path) === 0
          );
          // If it exists, it means that the sublist of the current item needs to be opened
          if (cItem) {
            setOpenKey([...openKey, item.path])
          }

          // <SubMenu>
          pre.push(
            <SubMenu
              key={item.path}
              title={
                <span>
                  {item.icon ? <MenuIcon type={item.icon} /> : null}
                  <span>{item.title}</span>
                </span>
              }
            >
              {getMenuNodes(item.children)}
            </SubMenu>
          );
        }
      return pre;
    }, []);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const _items = reorder(
      menuTreeNode,
      result.source.index,
      result.destination.index
    );
    setMenuTreeNode(_items)
  };

  const handleMenuSelect = ({ key = "/dashboard" }) => {
    let menuItem = getMenuItemInMenuListByProperty(menus, "path", key);
    dispatch(addTag(menuItem));
  };
  
  useEffect(() => {
    const menuTreeNode = getMenuNodes(menus);
    setMenuTreeNode(menuTreeNode)  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
      <div className="sidebar-menu-container">
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {menuTreeNode && menuTreeNode.map((item, index) => (
                    <Draggable
                      key={item.key}
                      draggableId={item.key}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Menu
                            mode="inline"
                            theme="dark"
                            onSelect={handleMenuSelect}
                            selectedKeys={[path]}
                            defaultOpenKeys={openKey}
                            items = {[item]}
                          >
                          </Menu>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Scrollbars>
      </div>
    );
}

export default  AdminMenu;
