import React from "react";
// import { connect } from "react-redux";
import { MenuProps, Dropdown, Modal, Layout, Avatar } from "antd";
import {CaretDownOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { logout } from "@/store/actions";
import FullScreen from "@/components/FullScreen";
import Settings from "@/components/Settings";
import MenuCollapsed from "@/components/MenuCollapsed";
import BreadCrumb from "@/components/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";

import "./index.less";
const { Header } = Layout;

const LayoutHeader = () => {

  const { token } = useSelector(state => state.user)
  const { avatar } = useSelector(state => state.user.profile)
  const sidebarCollapsed = useSelector(state => state.app.sidebarCollapsed)
  const { fixedHeader, showSettings } = useSelector(state => state.settings)
  const dispatch = useDispatch();

  const handleLogout = (token) => {
    Modal.confirm({
      title: "Logout",
      content: "你確定嗎？",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => {
        dispatch(logout(token));
      },
    });
  };
  const items =  [
    // { label: (<Link to="/dashboard">Dashboard</Link>), key: 'dashboard' },
    { label: 'Logout', key: 'logout' }
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case "logout":
        handleLogout(token);
        break;
      default:
        break;
    }
  };
  
  const computedStyle = () => {
    let styles;
    if (fixedHeader) {
      if (sidebarCollapsed) {
        styles = {
          width: "calc(100% - 80px)",
        };
      } else {
        styles = {
          width: "calc(100% - 200px)",
        };
      }
    } else {
      styles = {
        width: "100%",
      };
    }
    return styles;
  };
  return (
    <>
      {fixedHeader ? <Header /> : null}
      <Header
        style={computedStyle()}
        className={fixedHeader ? "fix-header" : ""}
      >
        <MenuCollapsed />
        {/* <BreadCrumb /> */}
        <div className="right-menu">
          <FullScreen />
          {/* {showSettings ? <Settings /> : null} */}
          <div className="dropdown-wrap">
            <Dropdown menu={{items, onClick}}>
              <div>
                <Avatar shape="square" size="medium" src={avatar} />
                <CaretDownOutlined style={{ color: "rgba(0,0,0,.3)" }} />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
};

export default LayoutHeader;
