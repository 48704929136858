import React, { useState, useEffect, useRef } from 'react';

import { Form, Input, Row, Col, Button } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import UploadImage from '@/components/UploadImage';

import './index.less';

const Album = (props) => {
  const { form, albumImages } = props;
  const [disabledAlbum, setDisabledAlbum] = useState(false);

  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(albumImages);
    if (albumImages.length >= 6) setDisabledAlbum(true);
  }, [albumImages, disabledAlbum]);

  const onUploaded = (value) => {
    // {index, name, data} = value

    // remove
    let album = form.getFieldValue('album');

    if (value.data !== null) {
      //if empty
      if (album[value.index] === undefined) {
        album = [
          {
            title: '',
            description: '',
            image: {
              ...value.data,
              type: 'album',
            },
          },
        ];

        setImages({
          0: [
            {
              uid: value.data.uid,
              name: value.data.name,
              status: 'done',
              url: process.env.REACT_APP_S3_URL + value.data.thumbnail_260x195,
            },
          ],
        });
      } else {
        // update
        album[value.index] = {
          title: album[value.index].title,
          description: album[value.index].description,
          image: {
            ...value.data,
            type: 'album',
          },
        };

        setImages({
          ...images,
          [value.index]: [
            {
              uid: value.data.uid,
              name: value.data.name,
              status: 'done',
              url: process.env.REACT_APP_S3_URL + value.data.thumbnail_260x195,
            },
          ],
        });
      }
    } else {
      // remove
      album[value.index] = {
        title: album[value.index].title,
        description: album[value.index].description,
        image: null,
      };
    }
    form.setFieldValue('album', album);
  };

  return (
    <>
      <p>介紹</p>
      <Form.List
        name='album'
        rules={[
          {
            validator: async (_, names) => {
              setDisabledAlbum(names.length >= 6);
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} className='box-info'>
                <Col className={'box-left'}>
                  <Form.Item label='Album'>
                    <UploadImage
                      maxCount={1}
                      index={key}
                      name='image'
                      onUploaded={onUploaded}
                      fileList={images[key] !== undefined ? images[key] : []}
                    />
                  </Form.Item>
                  <Form.Item name={[name, 'image']} style={{ display: 'none' }}>
                    <Input type='hidden' />
                  </Form.Item>
                </Col>
                <Col className={'box-right'}>
                  <Form.Item label=' ' name={[name, 'description']}>
                    <Input.TextArea
                      style={{ height: '104px' }}
                      placeholder='Description'
                    />
                  </Form.Item>
                </Col>

                {/* {(key !== 0 && key !== 1) ? <Col span={24} className="remove-info">
                                <MinusCircleOutlined style={{fontSize: '18px', color: 'red'}} onClick={() => remove(name)} />
                            </Col> : null} */}
              </Row>
            ))}

            {/* <Form.Item>
                        <Button type="dashed" onClick={() => add()} disabled={disabledAlbum} icon={<PlusOutlined />}>
                        Add Album
                        </Button>
                    </Form.Item> */}
          </>
        )}
      </Form.List>
    </>
  );
};
export default Album;
