import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, message, Spin } from "antd";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import DocumentTitle from "react-document-title";
import { login } from "@/store/actions";
import { useSelector, useDispatch } from 'react-redux';
import "./index.less";

const Login = (props) => {
  const {token} = useSelector(state => state.user);
  const user = useSelector(state => state.user);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const handleLogin = (email, password) => {
    setLoading(true);

    dispatch(login(email, password)).then((data) => {
      
      if (data.success) {
        message.success("Success!");

      } else {  
        message.error(data.errors.message);
        setLoading(false);
      }
    })
    .catch((error) => {
      setLoading(false);
      message.error(error);
    });
  };

  const onFinish = () => {
    form.validateFields().then(values => {
      const { email, password } = values;
      handleLogin(email, password);
    })
    .catch(errorInfo => {
      console.log(errorInfo);
      message.error("Login failed!");
    });
  };

  if (token) {
    if (user?.profile?.menus) {
      return <Redirect to={user?.profile?.menus[0]?.path ?? '/'} />;
    }
    return <Redirect to="/applies" />;
  }
  return (
    <DocumentTitle title={"Eikan Admin"}>
      <div className="login-container">
        <Form 
          form={form}
          onFinish={onFinish}
          autoComplete="off" className="content">
          <div className="title">
            <h2>Admin</h2>
          </div>
          <Spin spinning={loading} tip="Loading...">
          <Form.Item name="email" rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "The email is required",
                    },
                    ]}>
              <Input
                  prefix={
                    <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                />
          </Form.Item>

          <Form.Item name="password" rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "The password is required",
                    },
                    ]}>
              <Input
                  prefix={
                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
          </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Login
              </Button>
            </Form.Item>
          </Spin>
        </Form>
      </div>
    </DocumentTitle>
  );
};

export default Login;
