const auth = 'auth'

const getUser = () => {
  return JSON.parse(localStorage.getItem(auth) ?? '[]')
}
const setUser = (user) => {
  return localStorage.setItem(auth, user)
}
const removeUser = () => {
  return localStorage.removeItem(auth)
}

export {
  getUser,
  setUser,
  removeUser
}
