import React, { useState, useEffect } from 'react';

import { Form, Input, Row, Col} from "antd";
import UploadImage from '@/components/UploadImage';

const Strong = (props) => {

    const {form, strongImages} = props

    const [images, setImages] = useState([])

    const onUploaded = (value) => {
          // {index, name, data} = value

        // remove
        let strongs = form.getFieldValue('strongs')

        if (value.data !== null) {

            //if empty
            if (strongs[value.index] === undefined) {
                strongs = [{
                    title: '',
                    description: '',
                    image: {
                        ...value.data,
                        type: 'strong',
                    }
                }]

                setImages({
                    0: [{
                        uid: value.data.uid,
                        name:  value.data.name,
                        status: 'done',
                        url: process.env.REACT_APP_S3_URL +  value.data.thumbnail_260x195
                    }]
                })

            } else {
                // update
                strongs[value.index] = {
                    title: strongs[value.index].title,
                    description: strongs[value.index].description,
                    image: {
                        ...value.data,
                        type: 'strong',
                    }
                }

                setImages({
                    ...images,
                    [value.index]: [{
                        uid: value.data.uid,
                        name:  value.data.name,
                        status: 'done',
                        url: process.env.REACT_APP_S3_URL +  value.data.thumbnail_260x195
                    }]
                })
            }

        } else {

            // remove
            strongs[value.index] = {
                title:  strongs[value.index].title,
                description:  strongs[value.index].description,
                image: null,
            }
            
        }
        form.setFieldValue('strongs', strongs)
        
    }

    useEffect(() => {
        setImages(strongImages)
    }, [strongImages])
    
    return (
        <>
            <p>課程特色</p>
            <Form.List name="strongs"
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        
                        <Row
                          key={key}
                          className="box-info"
                        >
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              label="クラス名"
                              name={[name, 'title']}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "The title is required",
                              //   },
                              // ]}
                            >
                              <Input placeholder="クラス名" maxLength={255} type="text" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                          <Form.Item
                            {...restField}
                            label="授業内容"
                            name={[name, 'description']}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "The description is required",
                            //   },
                            // ]}
                          >
                            <Input.TextArea rows={3} placeholder="授業内容" />
                          </Form.Item>
                          </Col>
                          <Col span={24}>    
                                <Form.Item label="Image">
                                    <UploadImage maxCount={1} index={key} name="image" onUploaded={onUploaded} fileList={images[key] !== undefined ? images[key] : []} />
                                </Form.Item>
                                <Form.Item name={[name, 'image']} style={{display: 'none'}}>
                                    <Input type="hidden" />
                                </Form.Item>
                          </Col>

                        </Row>
                      ))}
                    </>
                  )}
            </Form.List>
        </>
    )
}
export default Strong