import React, { useState, useEffect } from 'react';

import { Form, Select, Input, message} from "antd";
import { reqGetCourses } from "@/api/course";

const RelativeCouse = (props) => {
    const {form} = props
    const [courses, setCourses] = useState([])
    
    const handleChange = (objects) => {
        let ids = []
        for(let item of objects) { ids.push(item.value)}
        form.setFieldValue('relative_courses', ids)
    };

    const fetchCourses = async () => {
        try {
            let response = await reqGetCourses({page: 1, per_page: 100})

            let {success, data, errors} = response.data
            if (success) {
                let courses = []
                for (let item of data.result) {
                    courses.push({
                        label: item.name,
                        value: item.id
                    })
                }
                setCourses(courses)
            } else {
                message.error(errors.message)
            }
        } catch (e) {
            message.error(e.message)
        }
    }
   
    useEffect(() => {
        fetchCourses()
    }, [])

    return (
        <>
                <Form.Item label="Relative" name="relative_object">
                    <Select
                        labelInValue
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder=""
                        onChange={handleChange}
                        options={courses}
                    />
                </Form.Item>
                <Form.Item name="relative_courses" style={{display: 'none'}}>
                    <Input />
                </Form.Item>
            
        </>
    )
}
export default RelativeCouse