import request from '@/utils/request'

export function reqUploadImage(data) {
    return request({
      url: `/upload-image?tag=course`,
      method: 'post',
      data: data,
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //  } 
    })
}

export function reqUploadFile(data) {
  return request({
    url: `/upload-file?tag=file`,
    method: 'post',
    data: data,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //  } 
  })
}

export function reqUploadAvatar(data) {
  return request({
    url: `/upload-avatar?tag=avatars`,
    method: 'post',
    data: data,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //  } 
  })
}

  