import React, { useState, useEffect } from 'react';

import { Form, Input, Row, Col, Button, Divider} from "antd";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import UploadImage from '@/components/UploadImage';

const Chinese = (props) => {
    const {form, chineseImages} = props

    const [disabledInfo, setDisabledInfo] = useState(false)
    const [disabledImage, setDisabledImage] = useState(false)


    const [images, setImages] = useState([])

    useEffect(() => {
      setImages(chineseImages)
    }, [chineseImages])
    const onUploaded = (value) => {
          // {index, name, data} = value

        // remove
        let chinese_example = form.getFieldValue('chinese_example')

        if (value.data !== null) {

            //if empty
            if (chinese_example[value.index] === undefined) {
              chinese_example = [{
                    title: '',
                    description: '',
                    image: {
                        ...value.data,
                        type: 'chinese_example',
                    }
                }]

                setImages({
                    0: [{
                        uid: value.data.uid,
                        name:  value.data.name,
                        status: 'done',
                        url: process.env.REACT_APP_S3_URL +  value.data.thumbnail_260x195
                    }]
                })

            } else {
                // update
                chinese_example[value.index] = {
                    title: chinese_example[value.index].title,
                    description: chinese_example[value.index].description,
                    image: {
                        ...value.data,
                        type: 'chinese_example',
                    }
                }

                setImages({
                    ...images,
                    [value.index]: [{
                        uid: value.data.uid,
                        name:  value.data.name,
                        status: 'done',
                        url: process.env.REACT_APP_S3_URL +  value.data.thumbnail_260x195
                    }]
                })
            }

        } else {

            // remove
            chinese_example[value.index] = {
                title:  chinese_example[value.index].title,
                description:  chinese_example[value.index].description,
                image: null,
            }
            
        }
        form.setFieldValue('chinese_example', chinese_example)
        
    }

    return (
        <>
            <p>コースのご案内</p>
            <Form.List name="chinese_info"
                    rules={[
                        {
                          validator: async (_, names) => {
                            setDisabledInfo(names.length >= 10)
                          },
                        },
                      ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, { errors }) => (
                        <Row
                          key={key}
                          className="box-info"
                        >
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              label="クラス名"
                              name={[name, 'title']}
                              rules={[
                                {
                                  required: true,
                                  message: "The title is required",
                                },
                              ]}
                            >
                              <Input placeholder="クラス名" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                          <Form.Item
                            {...restField}
                            label="授業内容"
                            name={[name, 'description']}
                            rules={[
                              {
                                required: true,
                                message: "The description is required",
                              },
                            ]}
                          >
                            <Input.TextArea rows={3} placeholder="授業内容" />
                          </Form.Item>
                          </Col>
                            {key !== 0 ? <Col span={24} className="remove-info">
                                <MinusCircleOutlined style={{fontSize: '18px', color: 'red'}} onClick={() => remove(name)} />
                            </Col> : null}
                        </Row>
                      ))}

                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} disabled={disabledInfo} icon={<PlusOutlined />}>
                          Add Info
                        </Button>
                      </Form.Item>
                    </>
                  )}
            </Form.List>
            <Divider />
            <p>実施事例</p>
            <Form.List name="chinese_example"
                 rules={[
                    {
                      validator: async (_, names) => {
                        setDisabledImage(names.length >= 5)
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        
                        <Row
                          key={key}
                          className="box-info"
                        >
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              label="クラス名"
                              name={[name, 'title']}
                              rules={[
                                {
                                  required: true,
                                  message: "The title is required",
                                },
                              ]}
                            >
                              <Input placeholder="クラス名" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                          <Form.Item
                            {...restField}
                            label="授業内容"
                            name={[name, 'description']}
                            rules={[
                              {
                                required: true,
                                message: "The description is required",
                              },
                            ]}
                          >
                            <Input.TextArea rows={3} placeholder="授業内容" />
                          </Form.Item>
                          </Col>
                          <Col span={24}>    
                                <Form.Item label="Image">
                                    <UploadImage maxCount={1} index={key} name="image" onUploaded={onUploaded} fileList={images[key] !== undefined ? images[key] : []} />
                                </Form.Item>
                                <Form.Item name={[name, 'image']} style={{display: 'none'}}>
                                    <Input type="hidden" />
                                </Form.Item>
                          </Col>

                          {key !== 0 ? <Col span={24} className="remove-info">
                                <MinusCircleOutlined style={{fontSize: '18px', color: 'red'}} onClick={() => remove(name)} />
                            </Col> : null}

                        </Row>
                      ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} disabled={disabledImage} icon={<PlusOutlined />}>
                            Add Example
                            </Button>
                        </Form.Item>

                    </>
                  )}
            </Form.List>
        </>
    )
}
export default Chinese