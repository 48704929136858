import React from "react";
import { Tooltip } from "antd";
import { SettingOutlined } from  '@ant-design/icons';
import { toggleSettingPanel } from "@/store/actions";
import { useDispatch } from 'react-redux';
import "./index.less";

const Settings = (props) => {
  const dispatch =  useDispatch();
  const toggleSetting = () => dispatch(toggleSettingPanel())
  return (
    <div className="settings-container">
      <Tooltip placement="bottom" title="Settings">
        <SettingOutlined onClick={toggleSetting} />
      </Tooltip>
    </div>
  );
}
export default Settings;

