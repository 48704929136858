import React, { useEffect, useState, useRef } from 'react';

import { Form, Input, Checkbox, Select, Card, Row, Col, Radio, Divider, InputNumber, message, Button } from "antd";
import { reqGetCourse, reqUpdateCourse } from "@/api/course";
import { reqOptions } from "@/api/option";
import WeekSchedule from './week-schedule'
import UploadImage from '@/components/UploadImage';
import Chinese from './chinese'
import Japan from './japan'
import Company from './company'
import Contact from './contact'
import Album from './album'
import Strong from './strong'
import RelativeCouse from './relative-course'
import ChineseData from './chinese-data';
import {Color, Solver, hexToRgb } from './filter-color'
import './index.less';

const { TextArea } = Input;

const CourseEdit = (props) => {

    const { history, match } = props;
    const { id } = match.params;
    const [optionSettings, setOptionSettings] = useState([])

    const refName = useRef(null);
    const refOutline = useRef(null);
    const refDes = useRef(null);

    const [form] = Form.useForm();
  
    const [plainRooms, setPlainRooms] = useState([])
    const [plainLevels, setPlainLevels] = useState([])
    const [plainNations, setPlainNations] = useState([])

    const plainFixedTime = ['固定時間', '可自由選擇'];
    const plainFixedTeacher = ['固定教師', '不同教師'];

    const options = [];
    for (let i = 1; i <= 30; i++) { options.push({ value: i, label: i})}

    const optionsTime = [];
    for (let i = 10; i <= 300; i = i + 10) { optionsTime.push({ value: i, label: i})}

   
    const [type, setType] = useState('DEFAULT');

    const [cover, setCover] = useState([]);
    const [strongImages, setStrongImages] = useState([]);
    const [albumImages, setAlbumImages] = useState([]);
    const [chineseImages, setChineseImages] = useState([]);
    const [companyImages, setCompanyImages] = useState([]);
    const [japanImages, setJapanImages] = useState([]);

    // const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
   
    const [weekSchedule, setWeekSchedule] = useState({
      'is_mon_morning': false,
      'is_tue_morning': false,
      'is_wed_morning':false,
      'is_thu_morning': false,
      'is_fri_morning': false,
      'is_sat_morning': false,
      'is_sun_morning': false,
      'is_mon_afternoon': false,
      'is_tue_afternoon': false,
      'is_wed_afternoon': false,
      'is_thu_afternoon': false,
      'is_fri_afternoon': false,
      'is_sat_afternoon': false,
      'is_sun_afternoon': false,
      'is_mon_evening': false,
      'is_tue_evening': false,
      'is_wed_evening': false,
      'is_thu_evening': false,
      'is_fri_evening': false,
      'is_sat_evening': false,
      'is_sun_evening': false
    });

    const onUploaded = (value) => {
      form.setFieldValue([value.name], value.data)
    }

    const onChangeType = (value) => {
      setType(value)

      // TODO
      if (value === 'CHINESE') {
        form.setFieldValue('chinese_info', [{title: '', description: ''}])
        form.setFieldValue('chinese_example', [{title: '', description: '', image: null}])
      } else if (value === 'JAPAN') {
        form.setFieldValue('japans', [{school_name: '', city1: '', city2: '', term: '', course: '', class: '', image: null}])
        setJapanImages([[]])
      } else if (value === 'COMPANY') {
        form.setFieldValue('company_example', [{title: '', description: '', image: null}])
        setCompanyImages([[]])
        // setSelectedCompanyIds([])
      } else {
        // DEFAULT
        form.setFieldsValue({
          chinese_info: null,
          chinese_example: null,
          company_example: null,
          japans: []
        })
      }
    }

    const [dynamicPrice, setDynamicPrice] = useState(false);
    const onChangePrice = (e) => {
      setDynamicPrice(e.target.checked)
      form.setFieldValue('price', undefined)
    }

    const handleUpdateSchedule = (name) => {

      setWeekSchedule({
        ...weekSchedule,
        [name]: weekSchedule[name] === 1 ? 0 : 1,
      })

      form.setFieldValue('schedules', {
        ...weekSchedule,
        [name]: weekSchedule[name] === 1 ? 0 : 1,
      })
      
    }

    const fetchData = async () => {

      try {
        let response = await reqOptions()
        const {success, data, errors} = response.data
        if (success) {

          setOptionSettings(data)

          let levels = [], rooms = [], nations = []
          for (let item of data) {
            if (item.id === 1) {
              for (let value of item.values) {
                levels.push(value.text)
              }
            }
            if (item.id === 10) {
              for (let value of item.values) {
                rooms.push(value.text)
              }
            }
            if (item.id === 2) {
              for (let value of item.values) {
                nations.push({
                  label: value.text,
                  value: value.text
                })
              }
            }
          }
          setPlainRooms(rooms)
          setPlainLevels(levels)
          setPlainNations(nations)
        } else {
          message.error(errors.message)
        }

      } catch (e) {
        console.log(e)
        message.error('Server error')
      }

      try {
        const response = await reqGetCourse(id)
        let {success, data} = response.data;
        if (!success) {
          history.replace('/error/404');
          return
        } 

        let cover = null, album = [], albumImages = [], 
        strongs = [], strongImages = [], 
        chinese_info = [], 
        chinese_example = [], chinese_exampleImages = [],
        company_example = [], company_exampleImages = [],
        japans = [], japanImages = []

        for (let item of data.extras) {

          if (item.type === 'cover') {
            if (item.thumbnail_260x195 !== null) {
              setCover([{
                uid: item.uid,
                name: item.name,
                status: 'done',
                url: process.env.REACT_APP_S3_URL + item.thumbnail_260x195
              }])
              cover = item
            }
            
          } 
          else if (item.type === 'album') {
            album.push({
              title: item.title,
              description: item.description,
              image: {
                ...item
              }
            })

            if (item.thumbnail_260x195 !== null) {
              albumImages.push([{
                  uid: item.uid,
                  name:  item.name,
                  status: 'done',
                  url: process.env.REACT_APP_S3_URL +  item.thumbnail_260x195
              }])
            }
            

          } else if (item.type === 'strong') {

            strongs.push({
              title: item.title,
              description: item.description,
              image: {
                ...item
              }
            })
            if (item.thumbnail_260x195 !== null) {
              strongImages.push([{
                uid: item.uid,
                name:  item.name,
                status: 'done',
                url: process.env.REACT_APP_S3_URL +  item.thumbnail_260x195
            }])
            }
           

          }
          else if (item.type === 'chinese_info') {
            chinese_info.push({
              title: item.title,
              description: item.description,
            })
          } else if (item.type ==='chinese_example') {

            chinese_example.push({
              title: item.title,
              description: item.description,
              image: {
                ...item
              }
            })
            if (item.thumbnail_260x195 !== null) {
              chinese_exampleImages.push([{
                    uid: item.uid,
                    name:  item.name,
                    status: 'done',
                    url: process.env.REACT_APP_S3_URL +  item.thumbnail_260x195
              }])
            }
           
          }
          else if (item.type === 'company_example') {
            company_example.push({
              title: item.title,
              description: item.description,
              image: {
                ...item
              }
            })
            if (item.thumbnail_260x195 !== null) {
              company_exampleImages.push([{
                    uid: item.uid,
                    name:  item.name,
                    status: 'done',
                    url: process.env.REACT_APP_S3_URL +  item.thumbnail_260x195
              }])
            }
            
          }
        }

        // default is 6 images
        let lengh = album.length
        if (lengh !== 6) {
          for (let i = 0; i < 6 - lengh; i++) {
            album.push({title: '', description: '', image: undefined})
            albumImages.push([])
          }
        }
       
        setAlbumImages(albumImages)

        // strong
        if (strongs.length > 0) {
          if (strongs.length === 1) {
            strongs.push({title: '', description: '', image: null})
            strongs.push({title: '', description: '', image: null})
            strongImages.push([])
            strongImages.push([])
          } else if(strongs.length === 2) {
            strongs.push({title: '', description: '', image: null})
            strongImages.push([])
          }
        } else {
          strongs = [{title: '', description: '', image: null}, {title: '', description: '', image: null}, {title: '', description: '', image: null}]
          strongImages = [[], [], []]
        }
        setStrongImages(strongImages)
        
        let contacts = data.contacts.length > 0 ? data.contacts : [{
          contact_name: '',
          contact_phone: '',
          contact_address: '',
          contact_time: '',
        }]

        setType(data.type)

        // CHINESE
        if (data.type === 'CHINESE') {

          // empty then set default
      
          if (chinese_info.length === 0) {
            // chinese_info = ChineseData
            chinese_info = []
          }
          // default 1 example
          if (chinese_example.length === 0) {
            chinese_example = [{title: '', description: '', image: null}]
            chinese_exampleImages.push([])
          }
          setChineseImages(chinese_exampleImages)

        } else if (data.type === 'JAPAN') {

          if (data.japans.length === 0) {
            japans = [{school_name: '', city1: '', city2: '', term: '', course: '', class: '', website: '', image: null}]
            japanImages.push([])
          } else {
            for(let item of data.japans) {
              japans.push({
                school_name: item.school_name,
                city1: item.city1,
                city2: item.city2,
                term: item.term,
                course: item.course,
                class: item.class,
                website: item.website,
                image: item.image,
              })

              if (item.image !== null && item.image !== '') {
                japanImages.push([{
                    uid: item.image.uid,
                    name:  item.image.name,
                    status: 'done',
                    url: process.env.REACT_APP_S3_URL +  item.image.thumbnail_260x195
                  }])
              }
              
            }
          }
          setJapanImages(japanImages)
        } 
        else if (data.type === 'COMPANY') {

          // default 5 example
          let lenght = company_example.length
   
          if (lenght < 5) {
            for (let i = 0; i < 5 - lenght; i++) {
              company_example.push({title: '', description: '', image: null})
              company_exampleImages.push([])
            }
          }

          setCompanyImages(company_exampleImages)        
          // setSelectedCompanyIds(data.company_selected)

        } else {

        }

        form.setFieldsValue({
          id: data.id,
          name: data.name,
          main_color: data.main_color,
          main_filter: data.main_filter,
          sub_color: data.sub_color,
          outline: data.outline,
          description: data.description,
          cover: cover,
          slug: data.slug,
          status: data.status,
          rooms: data.rooms,
          levels: data.levels,
          min_number: data.min_number,
          max_number: data.max_number,
          time_from: data.time_from,
          time_to: data.time_to,
          price: data.price,
          is_dynamic_price: data.is_dynamic_price,
          nations: data.nations,
          fixed_time: data.fixed_time,
          fixed_teacher: data.fixed_teacher,
          recommend1: data.recommend1,
          recommend2: data.recommend2,
          recommend3: data.recommend3,
          payment: data.payment,
          materials: data.materials,
          strongs: strongs,
          album: album,
          contacts: contacts,
          relative_object: data.relative_object,
          relative_courses: data.relative_courses,
          type: data.type,
          chinese_info: chinese_info,
          chinese_example: chinese_example,
          japans: japans,
          company_example: company_example,
          company_selected: data.company_selected
          
        });
        
        let weekSchedule = {
          'is_mon_morning': data.is_mon_morning,
          'is_tue_morning': data.is_tue_morning,
          'is_wed_morning': data.is_wed_morning,
          'is_thu_morning': data.is_thu_morning,
          'is_fri_morning': data.is_fri_morning,
          'is_sat_morning': data.is_sat_morning,
          'is_sun_morning': data.is_sun_morning,
          'is_mon_afternoon': data.is_mon_afternoon,
          'is_tue_afternoon': data.is_tue_afternoon,
          'is_wed_afternoon': data.is_wed_afternoon,
          'is_thu_afternoon': data.is_thu_afternoon,
          'is_fri_afternoon': data.is_fri_afternoon,
          'is_sat_afternoon': data.is_sat_afternoon,
          'is_sun_afternoon': data.is_sun_afternoon,
          'is_mon_evening': data.is_mon_evening,
          'is_tue_evening': data.is_tue_evening,
          'is_wed_evening': data.is_wed_evening,
          'is_thu_evening': data.is_thu_evening,
          'is_fri_evening': data.is_fri_evening,
          'is_sat_evening': data.is_sat_evening,
          'is_sun_evening': data.is_sun_evening,
        }

        setWeekSchedule(weekSchedule)
        form.setFieldValue('schedules', weekSchedule)

        setDynamicPrice(data.is_dynamic_price)

      } catch (e) {
        message.error(e.message)
      }

    }
    
    const onFinish = async (values) => {

      // handle data
      let extras = [];
      if (values.cover !== undefined) extras.push({...values.cover, type: 'cover'})
      if (values.album !== undefined) {
        for (let item of values.album) {
          delete item.image?.title
          delete item.image?.description

          if (item.description === '' && item.image === undefined) continue
          if (item.description === '' && item.image === null) continue
          if (item.description === null && item.image === undefined) continue
          if (item.description === null && item.image === null) continue

          extras.push({
            title: item.title,
            description: item.description,
            ...item.image,
            type: 'album'
          })
        }
      }

      if (values.strongs !== undefined) {
        for (let item of values.strongs) {
          delete item.image?.title
          delete item.image?.description
          extras.push({
            title: item.title,
            description: item.description,
            ...item.image,
            type: 'strong'
          })
        }
      }

      if (values.chinese_info !== undefined) {
        for (let item of values.chinese_info) {
          extras.push({
            title: item.title,
            description: item.description,
            type: 'chinese_info'
          })
        }
      }
      if (values.chinese_example !== undefined) {
        for (let item of values.chinese_example) {

          delete item.image?.title
          delete item.image?.description

          extras.push({
            title: item.title,
            description: item.description,
            ...item.image,
            type: 'chinese_example'
          })
        }
      }

      if (values.company_example !== undefined) {
        for (let item of values.company_example) {
          delete item.image?.title
          delete item.image?.description

          if (item.title === '' && item.description === '' && item.image === undefined) continue
          if (item.title === '' && item.description === '' && item.image === null) continue
          if (item.title === '' && item.description === null && item.image === undefined) continue
          if (item.title === '' && item.description === null && item.image === null) continue
          if (item.title === null && item.description === '' && item.image === undefined) continue
          if (item.title === null && item.description === '' && item.image === null) continue
          if (item.title === null && item.description === null && item.image === undefined) continue
          if (item.title === null && item.description === null && item.image === null) continue

          extras.push({
            title: item.title,
            description: item.description,
            ...item.image,
            type: 'company_example'
          })
        }
      }

      let input = {
        ...values.schedules,
        id: values.id,
        name: values.name,
        main_color: values.main_color,
        main_filter: values.main_filter,
        sub_color: values.sub_color,
        outline: values.outline,
        materials: values.materials,
        min_number: values.min_number,
        max_number: values.max_number,
        time_from: values.time_from,
        time_to: values.time_to,
        is_dynamic_price: (type === 'DEFAULT' || type === 'CHINESE') ? values.is_dynamic_price : 0,
        payment: values.payment,
        price: values.price,
        description: values.description,
        recommend1: values.recommend1,
        recommend2: values.recommend2,
        recommend3: values.recommend3,
        contacts: values.contacts,
        extras: extras,
        is_zhongshan_place: (type === 'DEFAULT' || type === 'CHINESE') ? (values.rooms.includes('中山教室') ? 1: 0) : 0,
        is_east_place: (type === 'DEFAULT' || type === 'CHINESE') ? (values.rooms.includes('東區教室') ? 1: 0) : 0,
        is_online_place: (type === 'DEFAULT' || type === 'CHINESE') ?  (values.rooms.includes('線上教學') ? 1: 0) : 0,
        is_teacher_place: (type === 'DEFAULT' || type === 'CHINESE') ? (values.rooms.includes('可外派教師') ? 1: 0) : 0,
        is_level_biginer: (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('初學者') ? 1: 0) : 0,
        is_level_n5:  (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('初級N5') ? 1: 0) : 0,
        is_level_n4:  (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('中級N4') ? 1: 0) : 0,
        is_level_n3: (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('實用N3') ? 1: 0) : 0,
        is_level_n2: (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('高級N2') ? 1: 0) : 0,
        is_level_n1: (type === 'DEFAULT' || type === 'CHINESE') ? (values.levels.includes('高級以上N1') ? 1: 0) : 0,
        is_japanese_and_taiwanese: (type === 'DEFAULT' || type === 'CHINESE') ? (values.nations.includes('日籍或台籍') ? 1: 0) : 0,
        is_japanese: (type === 'DEFAULT' || type === 'CHINESE') ? (values.nations.includes('日籍') ? 1: 0) : 0,
        is_taiwanese: (type === 'DEFAULT' || type === 'CHINESE') ? (values.nations.includes('台湾人') ? 1: 0) : 0,
        is_other_nation: (type === 'DEFAULT' || type === 'CHINESE') ? (values.nations.includes('可指定') ? 1: 0) : 0,
        is_fixed_time: (type === 'DEFAULT' || type === 'CHINESE') ? (values.fixed_time === '固定時間' ? 1: 0) : 0,
        is_fixed_teacher: (type === 'DEFAULT' || type === 'CHINESE') ? (values.fixed_teacher === '固定教師' ? 1: 0) : 0,
        type: values.type,
        company_selected: values.company_selected,
        relative_courses: values.relative_courses,
        japans: values.japans,
      }

      try {
        const response = await reqUpdateCourse(input)
        let {success, errors} = response.data;
        if (success) {
          message.success('成功')
          history.replace('/courses');
          return
        } else {
          message.error(errors.message)
          return
        }

      } catch (e) {
        console.log(e)
        message.error('Server error')
      }
    };

    const onFinishFailed = (errorInfo) => {
      console.log(errorInfo)
      for (let error of errorInfo.errorFields) {
        if (error.name[0] === 'name') {
          refName.current.focus()
          return
        }
        if (error.name[0] === 'outline') {
          refOutline.current.focus()
          return
        }
        if (error.name[0] === 'description') {
          refDes.current.focus()
          return
        }
      }
    };

    const changColor = e => {
      const rgb = hexToRgb(e.target.value)
      const color = new Color(rgb[0], rgb[1], rgb[2]);
      const solver = new Solver(color);
      const result = solver.solve();
      form.setFieldValue('main_filter', result.filter.replace('filter: ', ''))
    }

    useEffect(() => {
      let isMounted = false;
      document.title = 'Edit Course | Eikan Admin'
      if (!isMounted) {
        fetchData();
      }
      return () => {
        isMounted = true
      }
     
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
      <div className="app-container">
        <Card title="Edit">
            <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
              <Form.Item name="id" style={{display: 'none'}}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item label="クラス名" name="name" rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "The name is required",
                          },
                          ]}>
                    <Input placeholder="クラス名" ref={refName} maxLength={255}  />
                </Form.Item>

                <Form.Item label="Outline" name="outline" rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "The outline is required",
                          },
                          ]}>
                    <Input.TextArea rows={2} placeholder="Outline"  ref={refOutline}/>
                </Form.Item>
                <Form.Item label="基本説明" name="description" rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "The description is required",
                          },
                          ]}>
                    <TextArea rows={4} placeholder="基本説明"  ref={refDes}/>
                </Form.Item>
                <Form.Item label="覆蓋">
                      <UploadImage maxCount={1} index={0} name="cover" onUploaded={onUploaded} fileList={cover} />
                </Form.Item>
                <Form.Item label="" name="cover" style={{display: 'none'}}>
                  <Input type="hidden" />
                </Form.Item>

                <Divider />

                {type === 'DEFAULT'? <>

                  <Form.Item label="Main color" name="main_color">
                  <Input onChange={changColor} style={{ width: 187 }} type="color" placeholder="Main color" />
                  </Form.Item>
                  <Form.Item name="main_filter" style={{display: 'none'}}>
                      <Input type="hidden" />
                  </Form.Item>
                  <Form.Item label="Sub color" name="sub_color">
                      <Input style={{ width: 187 }} type="color" placeholder="Sub color" />
                  </Form.Item>
                  <Form.Item label="上課地點" name="rooms" rules={[
                            {
                              required: true,
                              message: "The rooms is required",
                            },
                            ]}>
                      <Checkbox.Group options={plainRooms} />
                  </Form.Item>

                  <Form.Item label="等級" name="levels" rules={[
                            {
                              required: true,
                              message: "The level is required",
                            },
                            ]}>
                      <Checkbox.Group options={plainLevels} />
                  </Form.Item>

                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="開課人數" name="min_number" rules={[
                                {
                                  required: true,
                                  message: "The number is required",
                                },
                                ]} initialValue="1">
                            <Select style={{ width: 187 }} options={options} />
                      </Form.Item>

                    </Col>
                    <Col>
                        <Form.Item label=" " name="max_number">
                            <Select style={{ width: 187 }}  options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="每次的授課時間" name="time_from" rules={[
                                {
                                  required: true,
                                  message: "The time_from is required",
                                },
                                ]} initialValue="10">
                            <Select style={{ width: 187 }} options={optionsTime} />
                      </Form.Item>

                    </Col>
                    <Col>
                        <Form.Item label=" " name="time_to">
                            <Select style={{ width: 187 }}  options={optionsTime} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="最低費用(堂)" name="price" initialValue="0" >
                            <InputNumber addonAfter="NTD" style={{width: '100%'}} placeholder="最低費用(堂)"  disabled={dynamicPrice}/>
                      </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label=" " name="is_dynamic_price">
                            <Checkbox onChange={onChangePrice} checked={dynamicPrice}>預約諮詢</Checkbox>
                        </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="教師的國籍" name="nations" rules={[
                            {
                              required: true,
                              message: "The nations is required",
                            },
                            ]}>
                        <Select style={{ width: 187 }} options={plainNations} />
                  </Form.Item>
                  
                  <Form.Item label="上課時間" name="fixed_time" rules={[
                              {
                                required: true,
                                message: "The time is required",
                              },
                              ]}>
                        <Radio.Group options={plainFixedTime} />
                    </Form.Item>

                  <Form.Item label="教師異動" name="fixed_teacher" rules={[
                            {
                              required: true,
                              message: "The teacher is required",
                            },
                            ]}>
                      <Radio.Group options={plainFixedTeacher} />
                  </Form.Item>
               
                  <Divider />

                  <Form.Item label="推薦對象" name="recommend1">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend2">
                      <TextArea rows={3} placeholder="推薦對象" />
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend3">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Divider />
                  
                  <Album form={form} albumImages={albumImages}/>
                  <Divider />

                  <Strong form={form} strongImages={strongImages} />

                  <Divider />

                  <Form.Item label="開校状況" >
                    <WeekSchedule schedules={weekSchedule} handleUpdateSchedule={handleUpdateSchedule}/>
                  </Form.Item>

                  <Form.Item name="schedules" style={{display: 'none'}}>
                    <Input type="hidden" />
                  </Form.Item>
                    
                  <Divider />
                  
                    <Form.Item label="報名前的注意事項" name="payment">
                      <TextArea rows={5} placeholder="報名前的注意事項" />
                    </Form.Item>

                    <Form.Item label="使用教材" name="materials">
                      <TextArea rows={5} placeholder="使用教材" />
                    </Form.Item>
                    <Divider />

                    <RelativeCouse form={form} />  
                    
                    <Divider />

                </> : null}

                {type === 'CHINESE' ? <>
                 
                  <Form.Item label="上課地點" name="rooms" rules={[
                            {
                              required: true,
                              message: "The rooms is required",
                            },
                            ]}>
                      <Checkbox.Group options={plainRooms} />
                  </Form.Item>

                  <Form.Item label="等級" name="levels" rules={[
                            {
                              required: true,
                              message: "The level is required",
                            },
                            ]}>
                      <Checkbox.Group options={plainLevels} />
                  </Form.Item>

                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="開課人數" name="min_number" rules={[
                                {
                                  required: true,
                                  message: "The number is required",
                                },
                                ]} initialValue="1">
                            <Select style={{ width: 187 }} options={options} />
                      </Form.Item>

                    </Col>
                    <Col>
                        <Form.Item label=" " name="max_number">
                            <Select style={{ width: 187 }}  options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="每次的授課時間" name="time_from" rules={[
                                {
                                  required: true,
                                  message: "The time_from is required",
                                },
                                ]} initialValue="10">
                            <Select style={{ width: 187 }} options={optionsTime} />
                      </Form.Item>

                    </Col>
                    <Col>
                        <Form.Item label=" " name="time_to">
                            <Select style={{ width: 187 }}  options={optionsTime} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="start" gutter={8}>
                    <Col>    
                        <Form.Item label="最低費用(堂)" name="price" initialValue="0" >
                            <InputNumber addonAfter="NTD" style={{width: '100%'}} placeholder="最低費用(堂)"  disabled={dynamicPrice}/>
                      </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label=" " name="is_dynamic_price">
                            <Checkbox onChange={onChangePrice} checked={dynamicPrice}>預約諮詢</Checkbox>
                        </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="教師的國籍" name="nations" rules={[
                            {
                              required: true,
                              message: "The nations is required",
                            },
                            ]}>
                        <Select style={{ width: 187 }} options={plainNations} />
                  </Form.Item>
                  
                  <Form.Item label="上課時間" name="fixed_time" rules={[
                              {
                                required: true,
                                message: "The time is required",
                              },
                              ]}>
                        <Radio.Group options={plainFixedTime} />
                    </Form.Item>

                  <Form.Item label="教師異動" name="fixed_teacher" rules={[
                            {
                              required: true,
                              message: "The teacher is required",
                            },
                            ]}>
                      <Radio.Group options={plainFixedTeacher} />
                  </Form.Item>
               
                  <Divider />
                  <p>推薦對象</p>
                  <Form.Item label="推薦對象" name="recommend1">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend2">
                      <TextArea rows={3} placeholder="推薦對象" />
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend3">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Divider />

                  <Strong form={form} strongImages={strongImages} />
                  <Divider />
                  
                  <Form.Item label="報名前的注意事項" name="payment">
                      <TextArea rows={5} placeholder="報名前的注意事項" />
                    </Form.Item>

                    <Form.Item label="使用教材" name="materials">
                      <TextArea rows={5} placeholder="使用教材" />
                    </Form.Item>
                    <Divider />

                  <Chinese form={form} chineseImages={chineseImages} />
                  <Divider />

                </> : null}

                {type === 'COMPANY' ? <>
                <p>推薦對象</p>
                <Form.Item label="推薦對象" name="recommend1">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend2">
                      <TextArea rows={3} placeholder="推薦對象" />
                  </Form.Item>
                  <Form.Item label="推薦對象" name="recommend3">
                      <TextArea rows={3} placeholder="推薦對象"/>
                  </Form.Item>
                  <Divider />

                  <Strong form={form} strongImages={strongImages} />
                  <Divider />
                  
                  <Company form={form} 
                    // selectedIds={selectedCompanyIds} 
                    companyImages={companyImages} 
                    />
                </> : null}

                {type === 'JAPAN' ? <>
                      
                </> : null}
                
                <Contact form={form} />
                {/* <Divider /> */}
               
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>

            </Form>
        </Card>
      </div>
    );

}
export default CourseEdit;
