import { setUserToken, resetUser, setUserInfo } from "./user";
import { reqLogin, reqLogout } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";
import { setUser, removeUser } from "@/utils/localStorage";

export const login = (email, password) => async (dispatch) => {
  try {
    const response = await reqLogin({email, password})
    const {success, data, errors} = response.data
    if (success) {

      // custom data
      const userInfo = {
        token: data.access_token,
        profile: {
          id: data.user.id,
          name: data.user.name,
          avatar: data.user.avatar,
          menus: data.user.menus.length > 0 ? data.user.menus.map(item => {
            return {
              id: item.id,
              title: item.title,
              path: item.path,
              icon: item.icon,
              order: item.order,
            }
          }).sort((a, b) => {return a.order - b.order;}) : [], // order asc via order
        }
      }
      dispatch(setUserInfo(userInfo));
      // set in browser
      setToken(data.access_token);
      setUser(JSON.stringify(userInfo.profile))
    }
    return response.data

  } catch (e) {
    console.log(e);
    return {
      success: false,
      errors: {
        message: e?.response?.data?.errors.message ?? e.message,
      }
    }
  }
};

export const logout = (token) => async (dispatch) => {
  try {
    const response = await reqLogout(token)
    const {success, data, errors} = response.data
    if (success) {
      dispatch(resetUser());
      removeToken(); 
      removeUser(); 
    } 
    return response.data

  } catch (e) {
    console.log(e);
    return {
      success: false,
      errors: {
        message: e?.response?.data?.errors.message ?? e.message,
      }
    }
  }
};
